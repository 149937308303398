<template>
  <div class="status-card">
    <div class="font-14 font-700 color-150">{{ title }}</div>
    <div>
      <div class="font-24 color-100" style="padding: 0; margin: 0;line-height: normal;">{{ ist }}</div>
      <div class="font-12 color-150" style="line-height: normal;">{{ soll }}</div>
    </div>
    <p v-if="performanceVisible" class="performance font-14 color-danger font-400"><span>{{performance}}</span></p>
  </div>
</template>

<script>
export default {
  name: 'StatusCard',
  props: {
    title: {
      type: String,
      required: true
    },
    soll: {
      type: String,
      required: true
    },
    ist: {
      type: String,
      required: true
    },
    performance: {
      type: String,
    },
    performanceVisible: {
      type: Boolean,
      default: false
  }
}
}
</script>

<style scoped>
.status-card:not(:last-child) {
  border-right: 1px solid var(--color-gray-250);
}
.status-card:first-child {
  padding-left: 0;
}
.status-card {
  display: flex;
  flex-direction: column;
  gap: var(--margin-xs);
  width: 180px;
}
.performance {
  background: rgba(220, 53, 69, 0.10);
  border-radius: var(--border-radius-s);
  border: 1px solid rgba(220, 53, 69, 0.10);
  padding: var(--margin-xs) var(--margin-s);
}

</style>