<template>

  <basic-modal :modal-id="'sync-inventory-modal'" :size="'md'" @reset="resetModal"
    :confirm-button-text="$t('inventory.sync')" :confirm-action="handleSubmit">
    <template #title>
      {{ $t('inventory.finish') }}
    </template>
    <div class="body" ref="body">
      <LoadingAnimation v-if="loading" />
      <StatusCards :inventoryVenueWineState="inventoryVenueWineState" :performanceVisible="true"/>
      <Performance class="mb-l" :inventoryVenueWineState="inventoryVenueWineState" :winesToHide="inventoryVenueWineState.winesToHide.length"  :widthFromParent="performanceWidth"/>

      <p class="font-14 color-100 mb-m">{{ $t('inventory.syncExplainText') }}</p>
      <div class="flex-row gap-xs">
        <div>
          <b-form-checkbox class="inline" inline id="submit-inventory-checkbox" v-model="confirmCheckbox"
            name="submit-inventory-checkbox">
          </b-form-checkbox>
          <p class="font-14 color-100 inline">{{ $t('inventory.confirmSync') }}</p>
        </div>
        <div class="closure">
          <div class="font-14 w-700">{{ $t('inventory.closureAt') }}</div>
          <div class=""></div>
          <b-form-datepicker v-model="selectedClosureDate" id="selectedClosureDate" :locale="$i18n.locale"></b-form-datepicker>
        </div>
      </div>
    </div>
    <template #footer>

      <Button class="w-m" :onClick="handleSubmit" :buttonText="$t('inventory.syncInventory')"
        :disabled="syncButttonDisabled" :primary="true">
        <template v-slot:svg>
          <SyncIcon class="sync" />
        </template>
      </Button>
  

    </template>
  </basic-modal>
</template>
<script>
import BasicModal from '@/components/modals/BasicModal.vue'
import Button from '@/components/buttons/Button.vue'
import SyncIcon from '@/components/icons/SyncIcon.vue'
import StrapiService from '@/services/StrapiService'
import LoadingAnimation from '@/components/LoadingAnimation.vue'
import StatusCards from '../../cards/StatusCards.vue'
import Performance from '@/components/inventory/Performance.vue'


export default {
  name: 'SyncInventoryModal',
  data() {
    return {
      selectedClosureDate: new Date(),
      confirmCheckbox: false,
      loading: false,
      performanceWidth: 0
    }
  },
  components: {
    BasicModal,
    Button,
    SyncIcon,
    LoadingAnimation,
    StatusCards,
    Performance
  },
  props: {
    inventoryId: {
      type: Number,
      required: true
    },
    inventoryVenueWineState: {
      type: Object,
      required: true
    }
  },
  created() {

  },
  computed: {
    syncButttonDisabled() {
      if (this.selectedClosureDate !== null && this.confirmCheckbox !== false && !this.loading) {
        return false
      } else {
        return true
      }
    }
  },
  methods: {
    resetModal() {
      console.log('resetModalinInventory')
      setTimeout(()=>{
          this.performanceWidth = this.$refs.body ? this.$refs.body.clientWidth : 0
      }, 100)
    },
    handleSubmit() {
      this.loading = true;
      StrapiService.finishInventory(this.inventoryId, this.selectedClosureDate)
        .then((res) => {
          if (res.status === 200) {
            this.$store.commit("setAlertText", { text: this.$t("inventory.alerts.successSync"), variant: "info" });
            this.$bvModal.hide('sync-inventory-modal');
            this.$emit('inventorySynced');
          } else {
            console.log('Unerwarteter Statuscode:', res.status);
          }
        })
        .catch((error) => {
          this.$bvModal.hide('sync-inventory-modal');
          console.log('Fehler:', error);
          this.$store.commit("setAlertText", { text: this.$t("inventory.alerts.errorSync"), variant: "danger" });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    createReport() {
      console.log('createReport')
    }
  }
}
</script>
<style scoped>
.body {
  padding: var(--margin-m) 0;
}
.closure {
  min-width: 220px;
}
</style>
