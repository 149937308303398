<template>
  <div class="inventory-state font-12 color-150 bg-300 w500" :class="inventoryState">
    {{ renderInventoryStateText }}
  </div>
</template>

<script>
export default {
  name: 'InventoryState',
  props: {
    inventoryState: {
      type: String,
    }
  },
  computed: {
    renderInventoryStateText() {
      return this.$t(`inventory.state.${this.inventoryState}`)
    }
  }

}
</script>

<style scoped>
.inventory-state {
  width: fit-content;
  padding: 4px 8px;
  border-radius: 6px;
  height: 100%;
}
.open {
  background-color: var(--color-primary-500);
  color: var(--color-white);
}
</style>