<template>
  <basic-modal :modal-id="'submit-inventory-modal'" :size="'md'" @reset="resetModal">
    <template #title>
      {{ $t('inventory.submit') }}
    </template>
    <div class="body" ref="body"  >
      <LoadingAnimation class="w-100" v-if="loading" />
      <StatusCards :inventoryVenueWineState="inventoryVenueWineState" />
      {{ inventoryVenueWineStateForModal.open }}
      <Performance class="mb-l" :inventoryVenueWineState="inventoryVenueWineStateForModal" :widthFromParent="performanceWidth" />
      <p class="font-14 color-100 mb-m">{{ $t('inventory.submitExplainText') }}</p>
      <div class="flex-row gap-xs">
        <div>
          <b-form-checkbox v-model="checkBox" class="inline" inline id="submit-inventory-checkbox"
            name="submit-inventory-checkbox">
          </b-form-checkbox>
          <p class="font-14 color-100 inline">{{ $t('inventory.submitConfirmText') }}</p>
        </div>
      </div>
    </div>
    <template #footer>

      <Button class="w-m" :onClick="handleSubmit" :buttonText="$t('inventory.submit')"
        :disabled="checkBox === false && !loading" :primary="true">
        <template v-slot:svg>
          <SubmitIcon />
        </template>
      </Button>
    </template>



  </basic-modal>
</template>
<script>
import BasicModal from '@/components/modals/BasicModal.vue'
import Button from '@/components/buttons/Button.vue'
import SubmitIcon from '@/components/icons/SubmitIcon.vue'
import StrapiService from '@/services/StrapiService'
import LoadingAnimation from '@/components/LoadingAnimation.vue'
import StatusCards from '../../cards/StatusCards.vue'
import Performance from '@/components/inventory/Performance.vue'

export default {
  name: 'SubmitInventoryModal',
  data() {
    return {
      selectedClosureDate: null,
      checkBox: false,
      loading: false,
      inventoryVenueWineStateForModal: null,
      test: false,
      performanceWidth: 0
    }
  },
  components: {
    BasicModal,
    Button,
    SubmitIcon,
    LoadingAnimation,
    StatusCards,
    Performance

  },
  props: {
    inventoryId: {
      type: Number,
      required: true
    },
    inventoryVenueWineState: {
      type: Object,
    },
  },
  created() {
    this.inventoryVenueWineStateForModal = this.inventoryVenueWineState
    this.inventoryVenueWineStateForModal.open = true
  },
  computed: {
  },
  methods: {
    resetModal() {
      this.checkBox = false
      setTimeout(()=>{
          this.performanceWidth = this.$refs.body ? this.$refs.body.clientWidth : 0
      }, 100)
      
    },
    handleSubmit() {
      this.loading = true;
      StrapiService.transmitInventory(this.inventoryId)
        .then(() => {
          this.$emit('inventorySubmitted');
          this.$store.commit("setAlertText", { text: this.$t("inventory.alerts.successSubmit"), variant: "success" });
        })
        .catch((error) => {
          console.error('Fehler beim Übermitteln des Inventars:', error);
          this.$store.commit("setAlertText", { text: this.$t("inventory.alerts.errorSubmit"), variant: "danger" });
        })
        .finally(() => {
          this.loading = false;
          this.$bvModal.hide('submit-inventory-modal');
        });
    }
  }
}
</script>
<style scoped>
.modal-footer button:last-child {
  margin-left: 0;
}

.body {
  padding: var(--margin-m) 0;
}
</style>
