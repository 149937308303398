<template>
  <div ref="total" >
    <h3 class="font-14 color-150">Performance</h3>
    <div class="rectangles">
      <div ref="completeWines" class="green" :style="'width:'+completedWidth">
        <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3.93907 7.71512L1.23452 4.78974L0.333008 5.76487L3.93907 9.66537L11.6663 1.30716L10.7648 0.332031L3.93907 7.71512Z"
            fill="white" />
        </svg>
        <span v-if="inventoryVenueWineState">{{ inventoryVenueWineState.checkedWines.complete}}</span>

      </div>
      <div ref="divergentWines" class="yellow" :style="'width:'+divergentWidth">
        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M7.49967 13.6654C6.57745 13.6654 5.71079 13.4904 4.89967 13.1404C4.08856 12.7904 3.38301 12.3154 2.78301 11.7154C2.18301 11.1154 1.70801 10.4098 1.35801 9.5987C1.00801 8.78759 0.833008 7.92092 0.833008 6.9987C0.833008 6.07648 1.00801 5.20981 1.35801 4.3987C1.70801 3.58759 2.18301 2.88203 2.78301 2.28203C3.38301 1.68203 4.08856 1.20703 4.89967 0.857031C5.71079 0.507031 6.57745 0.332031 7.49967 0.332031C8.4219 0.332031 9.28856 0.507031 10.0997 0.857031C10.9108 1.20703 11.6163 1.68203 12.2163 2.28203C12.8163 2.88203 13.2913 3.58759 13.6413 4.3987C13.9913 5.20981 14.1663 6.07648 14.1663 6.9987C14.1663 7.92092 13.9913 8.78759 13.6413 9.5987C13.2913 10.4098 12.8163 11.1154 12.2163 11.7154C11.6163 12.3154 10.9108 12.7904 10.0997 13.1404C9.28856 13.4904 8.4219 13.6654 7.49967 13.6654ZM6.83301 7.66536V3.66536H8.16634V7.66536H6.83301ZM7.97467 10.1404C7.8469 10.2681 7.68856 10.332 7.49967 10.332C7.31079 10.332 7.15245 10.2681 7.02467 10.1404C6.8969 10.0126 6.83301 9.85425 6.83301 9.66537C6.83301 9.47648 6.8969 9.31814 7.02467 9.19036C7.15245 9.06259 7.31079 8.9987 7.49967 8.9987C7.68856 8.9987 7.8469 9.06259 7.97467 9.19036C8.10245 9.31814 8.16634 9.47648 8.16634 9.66537C8.16634 9.85425 8.10245 10.0126 7.97467 10.1404ZM11.283 10.782C10.2497 11.8154 8.98856 12.332 7.49967 12.332C6.01079 12.332 4.74967 11.8154 3.71634 10.782C2.68301 9.7487 2.16634 8.48759 2.16634 6.9987C2.16634 5.50981 2.68301 4.2487 3.71634 3.21536C4.74967 2.18203 6.01079 1.66536 7.49967 1.66536C8.98856 1.66536 10.2497 2.18203 11.283 3.21536C12.3163 4.2487 12.833 5.50981 12.833 6.9987C12.833 8.48759 12.3163 9.7487 11.283 10.782Z"
            fill="white" />
        </svg>
        <span v-if="inventoryVenueWineState">{{ inventoryVenueWineState.divergentStock}}</span>
      </div>
      <div ref="duplicateWines" class="blue" :style="'width:'+duplicateWidth">
        <DuplicateIcon :width="16" :height="16" fill="#FFF"/>
        <span v-if="inventoryVenueWineState">{{ inventoryVenueWineState.duplicateWines}}</span>
      </div>
      <div ref="winesToHide" class="grey">
        <HideIcon v-if="winesToHide"/><span>{{ winesToHide }}</span>
      </div>
    </div>

  </div>

</template>

<script>
import DuplicateIcon from '@/components/icons/DuplicateIcon.vue'
import HideIcon from '@/components/icons/HideIcon.vue'


export default {
  name: 'Performance',
  components: {
    DuplicateIcon,
    HideIcon

  },
  data () {
    return {
      totalWidth: 0
    }
  },
  props: {
    inventoryVenueWineState: {
      type: Object,
    },
    winesToHide: {
      type: Number,
    },
    widthFromParent: {
      type: Number,
    } 
  },
  methods: {
    setWidthsAndStyle() { 
      this.$refs.completeWines.style.width = this.completedWidth + 'px'
      this.$refs.completeWines.style.display = this.inventoryVenueWineState.checkedWines.complete > 0 ? 'flex' : 'none'
      this.$refs.winesToHide.style.width = this.hiddenWidth + 'px'
      this.$refs.winesToHide.style.display = this.inventoryVenueWineState.winesToHide.length > 0 ? 'flex' : 'none'
      this.$refs.divergentWines.style.width = this.divergentWidth + 'px'
      this.$refs.divergentWines.style.display = this.inventoryVenueWineState.divergentStock > 0 ? 'flex' : 'none'
      this.$refs.duplicateWines.style.width = this.duplicateWidth + 'px'
      this.$refs.duplicateWines.style.display = this.inventoryVenueWineState.duplicateWines > 0 ? 'flex' : 'none'
    },
    getTotalWidth() {
      this.totalWidth = this.$refs.total.clientWidth
    },
    recalculatWidths() {
      if (this.widthFromParent) {
        this.totalWidth = this.widthFromParent
      } else {
        this.getTotalWidth()
      }
      
      this.setWidthsAndStyle()
    }
  },
  computed: {
    hiddenWidth() {
      var realDuplicateWidth = this.duplicateWidth > 0 ? Math.max(this.duplicateWidth, 50) + 8 : 0  
      var realDivergentWidth = this.divergentWidth > 0 ? Math.max(this.divergentWidth, 50) + 8 : 0
      var realCompletedGap = this.completedWidth > 0 ? 8 : 0
      return (this.inventoryVenueWineState.winesToHide.length/this.inventoryVenueWineState.totalWines * this.totalWidth) - realDivergentWidth - realDuplicateWidth - realCompletedGap
    },
    completedWidth() {
      return this.inventoryVenueWineState.checkedWines.complete/this.inventoryVenueWineState.totalWines * this.totalWidth
    },
    divergentWidth() {
      return this.inventoryVenueWineState.divergentStock/this.inventoryVenueWineState.totalWines * this.totalWidth
    },
    duplicateWidth() {
      return this.inventoryVenueWineState.duplicateWines/this.inventoryVenueWineState.totalWines * this.totalWidth
    }
  },
  mounted() {
    this.recalculatWidths()
  },
  watch : {
    inventoryVenueWineState: {
      handler: function() {
        this.$nextTick(() => {
          this.recalculatWidths()
        })
      },
      deep: true
    },
    widthFromParent: {
      handler: function() {
        this.$nextTick(() => {
          this.recalculatWidths()
        })
      },
      deep: true
    }
  }

}

</script>

<style scoped>
.green {
  background: var(--color-primary-500);
}

.blue {
  background: var(--color-blue);
}

.yellow {
  background-color: var(--color-warning);
}
.grey {
  background-color: var(--color-gray-200);
}

.rectangles {
  display: flex;
  gap: var(--margin-xs);
}

.rectangles div {
  min-width: 50px;
  min-height: 26px;
  padding: var(--margin-xxs) var(--margin-xs);
  border-radius: var(--border-radius-s);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  gap: var(--margin-xs);
  transition: width 1s ease;
}
</style>